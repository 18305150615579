.dashboard-page {
  .dashboard-table {
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    flex-direction: row !important;
    background-color: #f9f9f9;
    padding: 20px 30px;

    &__max-scope-for-three {
      width: 50%;
      &-item {
        width: 25%;
      }
    }

    &__max-scope-for-four {
      width: 30%;
      &-item {
        width: 14%;
      }
    }

    &__block {
      width: calc(50% - 14px);
      height: max-content;
      border-radius: 32px;
      border: 1px solid $border-color;
      padding: 20px 0 0;
      background-color: #fff;
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 20px;
        button {
          padding: 10px;
        }
      }

      &-item__range-container {
        display: flex;
        align-items: center;
        gap: 8px;

        &_content {
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0;
          color: $color-80;
        }
      }
    }

    &__block-title {
      font-size: 18px;
      font-weight: 700;
    }

    .dashboard-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      caption {
        padding-bottom: 20px;
      }
      .table__head {
        border-top: none;
      }
      .table__row-head {
        background-color: #4a4a4a;
        .table__head-column {
          &:first-child {
            border-top-left-radius: 20px;
            padding-left: 24px;
          }
          &:last-child {
            border-top-right-radius: 20px;
          }
          color: #dbdbdb;
        }
      }
      .table__body {
        border: none;
        .table__row {
          &--opener {
            &:not(:last-child) {
              border-bottom: 1px solid #adadad;
            }
          }
          & .table__row-title {
            padding-left: 20px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
          }
          td {
            border-bottom: none !important;
            &::before {
              height: 0;
            }
            &:not(:last-child) {
              border-right: 1px solid #e6e6e6;
            }
            .due-date {
              display: flex;
              flex-direction: column;
              .pay-date {
                font-size: 12px;
              }
              .overdue-days {
                color: #adadad;
                font-size: 12px;
                &.red {
                  color: #f0504b;
                }
              }
            }
          }
        }
      }
      & .table__row:last-child {
        font-weight: bold;
        & .table__row-title {
          padding-left: 24px;
          color: #adadad;
        }
      }
    }
  }

  .dashboard-chart-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px;
    gap: 10px;
  }

  .dashboard-page-flex {
    display: flex;
    gap: 20px;
    width: 100%;

    &--container {
      flex: 1;
      display: flex;
      border: 1px solid $border-color;
      padding: 20px 24px;
      border-radius: 32px;

      .dashboard-flex {
        width: 60%;
        &-small {
          width: 40%;
        }
      }
      .filters__dashboard {
        display: flex;
        width: 100%;
        & > div {
          max-width: calc(100% - 35px);
        }
        &-text {
          color: #333333;
          display: flex;
          align-items: center;
          border-radius: 13px;
          padding: 6px 7px 4px 10px;
        }
        &-top {
          height: 26px;
          .filter__value-container {
            padding: 4px 36px 4px 10px !important;
            max-height: 26px;
            border-radius: 13px;
            font-weight: 400;
          }
        }
        &-button {
          background-color: $white !important;
          color: $color-80;
          padding: 10px;
        }
      }

      .dashboard-head {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 10px;
        &__amount {
          font-weight: 700;
          font-size: 20px;
          &-mid {
            color: $color-70;
            font-size: 14px;
          }
        }
        &__percent {
          color: #5aab18;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 2px;
          margin-top: 6px;
          &-icon {
            width: 24px;
            height: 24px;
            padding: 4px;
            background-color: rgba(90, 171, 24, 0.1);
            border-radius: 12px;
          }
          &.red {
            color: #f0504b;
            .dashboard-head__percent-icon {
              background-color: rgba(240, 80, 75, 0.1);
            }
          }
        }
      }
    }
  }

  .dashboard-chart {
    padding: 0 20px 20px;
  }
}
.dashboard-modal {
  .dashboard-draggable-container {
    position: relative;
  }
  .dashboard-modal-draggable {
    padding: 10px;
    gap: 10px;
    align-items: center;
  }

  .dashboard-drag {
    color: #808080;
    cursor: grab;
    &-grabbing {
      cursor: grabbing;
    }
  }

  .dashboard-placeholder {
    color: $color-80;
  }

  .dashboard-container {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding: 4px 8px 14px;
    &.opened {
      border-bottom: 1px solid $border-color;
    }

    .form__input-block {
      margin: 0;
    }
  }

  .dashboard-expenses {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 8px 12px 32px;
    border-bottom: 1px solid $border-color;
    &:not(:last-child) {
      padding-left: 8px;
      margin-left: 24px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      cursor: pointer;
    }
    &-container {
      width: 100%;
    }
    &-buttons {
      padding: 20px 30px 0 0;
    }

    .label {
      font-weight: 400;
      text-transform: none;
    }

    .form__checkbox-block,
    .checkbox-no-label {
      min-height: 100%;

      label {
        width: 100%;
        height: 100%;
        padding-left: 20px;

        &:before {
          left: 0;
          top: 0;
          transform: none;
        }

        svg,
        .clear-all-checkbox {
          top: 3px;
          left: 3px;
        }

        .clear-all-checkbox {
          top: 0;
          left: 0;
        }
      }
    }
  }

  .dashboard-subexpenses {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px 8px 12px 76px;
    border-bottom: 1px solid $border-color;
  }
}
