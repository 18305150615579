.salary-modal {
  .form__inputs-subwrapper {
    align-items: flex-start;
  }

  .total-input {
    opacity: 1;
    background-color: $white;

    + .form__label {
      opacity: 1;
    }
  }
}

.salaries-wrapper {
  & .table__body .table__row .table__data {
    height: 50px;
    padding: 0;
  }

  .table__data-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    .dropdown {
      &__button {
        justify-content: center !important;
      }
    }
  }

  .salary-data {
    display: block;
    position: relative;

    .total-block {
      text-align: center;
      font-weight: 500;
    }

    .year-display {
      top: -27px !important;
    }

    .salaries-dates {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .pay-tax-block {
      margin-top: 4px;
      display: flex;
      justify-content: center;
      font-weight: 400;
      padding: 0 10px;

      div:first-child {
        margin-right: 8px;
      }
    }
  }
  .employee-not-hired {
    width: 100%;
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 3px;
      background: #4a4a4a;
      transform: translate(-50%);
    }
  }
}

.totla-row-item {
  font-weight: 700;
  color: rgba(74, 74, 74, 0.6);
}

.current-month {
  color: $global-color;
}

.salary-delete-modal {
  .data-block {
    margin-bottom: 22px;

    &.flex-block {
      display: flex;

      & > div {
        margin-right: 22px;
      }
    }
  }

  .label-block {
    font-size: 12px;
    color: $color-dark;
    opacity: 0.5;
    margin-bottom: 4px;
  }
}
